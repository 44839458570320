import * as classNames from "classnames";

// import { WidgetOptions as ButtonWidgetOptions } from "../plugins/page/button";
import { ButtonConfigurationOptions } from "./button.util";

// Button Widget

export const getPreconfiguredButtonClassNames = (options: ButtonConfigurationOptions): string =>
    classNames({
        [`${options.buttonSize}`]: options.buttonSize && !options.usePreConfiguredButton && !options.displayLink,
        [`background-color-${options.buttonColor}`]: !options.usePreConfiguredButton && !options.displayLink && options.buttonColor?.includes("theme"),
        [`color-${options.buttonTxtColor}`]: options.buttonTxtColor?.includes("theme") && !options.usePreConfiguredButton,
        [`color-${options.linkColor}`]: options.displayLink && options.linkColor?.includes("theme") && !options.usePreConfiguredButton,
        ["icon-right"]: options.showIcons && options.iconRight,
        ["button-primary"]: options.usePreConfiguredButton && options.preConfiguredButton === "button-primary",
        ["button-secondary"]: options.usePreConfiguredButton && options.preConfiguredButton === "button-secondary",
        ["button-link"]: options.displayLink && !options.useAsBorderedButton,
        ["he-1"]: options.displayLink && options.linkHoverEffect === "he-1",
        ["he-2"]: options.displayLink && options.linkHoverEffect === "he-2",
        ["button--secondary"]: !options.displayLink && !options.usePreConfiguredButton && !options.useAsBorderedButton,
        ["bordered-button"]: options.useAsBorderedButton,
    });

// Result Panel Button Widget

export const getPreconfiguredResultButtonClassNames = (options: ButtonConfigurationOptions): string =>
    classNames({
        [options.buttonSize]: !!options.buttonSize && !options.displayLink,
        ["display-link"]: options.displayLink && !options.useAsBorderedButton,
        ["he-1"]: options.displayLink && options.linkHoverEffect === "he-1",
        ["he-2"]: options.displayLink && options.linkHoverEffect === "he-2",
        [`background-color-${options.buttonColor}`]: options.buttonColor?.includes("theme") && !options.useAsBorderedButton,
        [`color-${options.linkColor}`]: options.linkColor?.includes("theme"),
        ["button-primary"]: options.usePreConfiguredButton && options.preConfiguredButton === "button-primary",
        ["button-secondary"]: options.usePreConfiguredButton && options.preConfiguredButton === "button-secondary",
        ["button--primary"]: !options.displayLink && !options.usePreConfiguredButton,
        ["bordered-button"]: options.useAsBorderedButton,
        ["icon-right"]: options.showIcons && options.iconRight,
    });

export const getInlineStyle = (options: ButtonConfigurationOptions) => ({
    ...((options.linkColor?.includes("rgba") || options.linkColor?.includes("#")) && options.displayLink && { color: options.linkColor }),
    ...((options.buttonTxtColor?.includes("rgba") || options.buttonTxtColor?.includes("#")) && !options.displayLink && { color: options.buttonTxtColor }),
    ...((options.buttonColor?.includes("rgba") || options.buttonColor?.includes("#")) &&
        !options.displayLink &&
        !options.useAsBorderedButton &&
        !options.usePreConfiguredButton && { background: options.buttonColor }),
    ...(options.useAsBorderedButton && options.buttonColor?.includes("rgba") && { borderColor: options.buttonColor }),
});
