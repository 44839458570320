import * as FontAwesome from "react-fontawesome";
import * as React from "react";
import * as classNames from "classnames";

import { ButtonConfigurationOptions } from "../utils/button.util";

export interface ButtonIconProps {
    buttonConfiguration: ButtonConfigurationOptions;
}

export const ButtonIcon: React.FC<ButtonIconProps> = (props: ButtonIconProps): JSX.Element | null => {
    const { buttonConfiguration } = props;
    const styleProp = buttonConfiguration?.iconColor?.includes("rgba") ? { style: { color: buttonConfiguration.iconColor } } : undefined;
    if (buttonConfiguration.showIcons && buttonConfiguration.iconProperties) {
        return (
            <FontAwesome
                name={buttonConfiguration.iconProperties}
                className={classNames("icon", {
                    [`color-${buttonConfiguration.iconColor}`]: buttonConfiguration.iconColor?.includes("theme"),
                    ["ml-2"]: buttonConfiguration.iconRight,
                    ["mr-2"]: !buttonConfiguration.iconRight,
                })}
                {...styleProp}
            />
        );
    }
    return null;
};
