import { ACTIVITY, DYNAMIC_FIELD_CODE } from "../../../components/utils";
import { Activity, ResourceActivitiesDetailsWithDynamicField } from "../../page/activityPlanner/activityPlanner.types";
import { Content, getContent, getDynamicFieldCodeValue } from "../../../utils/content.util";
import { DynamicFieldDataResponse, DynamicFieldInfo, MxtsApi } from "@maxxton/cms-mxts-api";
import { FetchDynamicFieldCodesProps, LoadContentProps } from "./resultsPanelButton.types";
import { getActivitiesDetailsQueryParams, setCRPActivityArea, setCRPActivityCategory, setCRPActivityDynamicField } from "../../page/activityPlanner/activityPlanner.util";

import { DescriptionResult } from "../description/ResultsPanelDescription";
import { DynamicButtonProps } from "./ResultsPanelButton";
import { DynamicSearchButtonProps } from "../../page/dynamicSearchButton/DynamicSearchButton";
import { NumberMultiSelectOption } from "../../mxts/selectOption.types";
import { cloneDeep } from "lodash";
import { getLocalizedContent } from "../../../utils/localizedContent.util";
import { getMxtsEnv } from "../../mxts";
import { setActivityDynamicCodeInDescription } from "../../dynamic/description/description.utils";

export const loadContent = async ({ props, prioritizeResultPanelParams = false, widgetOptionsId }: LoadContentProps) => {
    const {
        availabilityState,
        context,
        options: { descriptionTypeMultiSelector, contentType },
        dynamicFilter,
    } = props;

    let content = await getContent({ ...props, prioritizeResultPanelParams, skipContentTypeSelectorCheck: true });

    const env = availabilityState?.env || (await getMxtsEnv(context, context.currentLocale.code));

    if (env) {
        const activity = (props as DynamicButtonProps)?.activity;
        if (contentType === ACTIVITY && widgetOptionsId) {
            if (activity) {
                let newContent = cloneDeep(content);
                newContent = await setCRPActivityArea(newContent as Activity, descriptionTypeMultiSelector, env);

                newContent = await setCRPActivityDynamicField({
                    activity: newContent,
                    selectedOptions: descriptionTypeMultiSelector,
                    env,
                    widgetOptionsId,
                    context: props.context,
                });
                newContent = await setCRPActivityCategory(newContent as Activity, context, env);
                content = newContent;
            } else {
                const activityIds = (props as DynamicSearchButtonProps)?.options?.activityIds;
                let selectedActivity;
                const activityIdsArray =
                    (activityIds?.length && activityIds.map((activity) => Number(activity?.value))) ||
                    (dynamicFilter?.resourceActivityDetailsId && [Number(dynamicFilter?.resourceActivityDetailsId)]) ||
                    [];
                if (activityIdsArray?.length) {
                    const detailParams = await getActivitiesDetailsQueryParams({
                        mxtsApi: context.mxtsApi,
                        env,
                        dynamicFilter,
                        resourceActivityDetailsIds: activityIdsArray,
                    });
                    const obtainedResourceActivities: Activity[] = (await MxtsApi.getResourceActivitiesDetails(env, detailParams))?.content;
                    selectedActivity = obtainedResourceActivities?.[0];
                }
                if (selectedActivity) {
                    content = await setActivityDynamicCodeInDescription({ selectedActivity, env, widgetOptionsId, context });
                }
            }
        }
    }
    return content;
};

export const retrieveLinkFromDynamicField = async <T extends Record<string, any>>(props: T, content: Content | Content[], usedForLinking?: boolean) => {
    const {
        options: { linking, dynamicFieldCodes: dynamicFieldCodesOption, descriptionTypeMultiSelector, _id, openInNewTab: openInNewTabOption },
        context,
        availabilityState,
    } = props;
    const { currentLocale, site } = context;
    const dynamicFieldCodes = usedForLinking ? linking?.dynamicFieldCodesForLinking : dynamicFieldCodesOption;
    const openInNewTab = usedForLinking ? (getLocalizedContent({ site, currentLocale, localizedContent: linking?.localizedLinkButtonOptions }) as any)?.openInNewTab : openInNewTabOption;

    const env = availabilityState?.env || (await getMxtsEnv(context));

    let descriptionsData: DynamicFieldDataResponse[] | DynamicFieldInfo[] | undefined = [];
    const descriptionType = usedForLinking ? [{ value: DYNAMIC_FIELD_CODE }] : descriptionTypeMultiSelector;
    if (descriptionType) {
        const descriptionsInfo = descriptionType?.map(mapDescriptions(content)).filter((desc: NumberMultiSelectOption) => desc?.value)?.[0]?.value;
        if (descriptionsInfo) {
            descriptionsData = descriptionsInfo as DynamicFieldDataResponse[];
        }
    }

    if (dynamicFieldCodes?.length && !descriptionsData?.length) {
        const response = await fetchDynamicFieldCodes({ dynamicFieldCodes, env, context, widgetOptionsId: _id, content });
        if (response?.length) {
            descriptionsData = response as DynamicFieldInfo[];
        }
    }
    return validateLink(descriptionsData?.[0], openInNewTab);
};

export const fetchDynamicFieldCodes = async ({ dynamicFieldCodes, env, context, widgetOptionsId, content }: FetchDynamicFieldCodesProps) => {
    const widgetOptionsDynamicFieldCodesPaths = ["dynamicFieldCodes", "dynamicFieldCodesForLinking"];
    const response = await Promise.all(
        dynamicFieldCodes.map((field) =>
            getDynamicFieldCodeValue({
                content,
                dynamicFieldCode: field?.value,
                env,
                apiContext: context,
                widgetOptionsId,
                widgetOptionsDynamicFieldCodesPaths,
            })
        )
    );
    return response.filter(Boolean);
};

export const mapDescriptions = (content: Content | Content[]) => (descriptionType: NumberMultiSelectOption): DescriptionResult | null => {
    if (content) {
        switch (descriptionType?.value) {
            case DYNAMIC_FIELD_CODE: {
                return {
                    value: (content as ResourceActivitiesDetailsWithDynamicField)?.dynamicFieldData || "",
                    type: DYNAMIC_FIELD_CODE,
                };
            }
        }
    }
    return null;
};

export const urlRegex = /^(?:(?:https?):\/\/)?(?:www\.)?[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*(?:\.[a-zA-Z]{2,})(?:\/\S*)?$/;

export const validateLink = (dynamicField: DynamicFieldInfo | DynamicFieldDataResponse, openInNewTab?: boolean) => {
    if (dynamicField) {
        const { value } = dynamicField;
        const url = Array.isArray(value) ? value[0]?.value : value;
        if (url && urlRegex.test(url.trim())) {
            return { target: openInNewTab ? "_blank" : "_self", url };
        }
    }
};
