import * as FontAwesome from "react-fontawesome";
import * as React from "react";
import * as classNames from "classnames";

import { Template, TemplateApi, WithId } from "@maxxton/cms-api";
import { UrlLinkParams, UrlParamsUtil } from "../../../utils/urlparam.util";
import { getInlineStyle, getPreconfiguredResultButtonClassNames } from "../../../utils/buttonOptions.util";
import { isEqual, setOpacityOnHide } from "../../../components/utils";
import { loadContent, retrieveLinkFromDynamicField, urlRegex } from "../../resultsPanel/button/resultsPanelButton.util";
import { parse, stringify } from "query-string";

import { AVAILABILITY_CONSTANTS } from "../../../utils/constants";
import { AccommodationType } from "../../mxts";
import { AvailabilityState } from "../../../redux/reducers/availability.types";
import { AvailabilityUtil } from "../../../utils/availability.util";
import { ButtonIcon } from "../../../components/ButtonIcon";
import { CMSProvidedProperties } from "../../../containers/cmsProvider.types";
import { ConfirmAddOnsComponent } from "../../dynamic/Bill/ConfirmAddOnsComponent";
import { Content } from "../../../utils/content.util";
import { DynamicFilter } from "../../../redux/reducers/dynamicFilter.types";
import { MyEnvState } from "../../../redux/reducers/myEnv/myEnvState";
import { NavLink } from "reactstrap";
import { PageWidgetBaseProps } from "../pageWidget.types";
import { RevealerContext } from "../../shared/revealer/Revealer";
import { SmartLink } from "../../../components/SmartLink";
import { State } from "../../../redux";
import { TemplateWidget } from "../template/Template";
import { WidgetOptions } from "./";
import { connect } from "react-redux";
import { isClientSide } from "../../../utils/generic.util";
import { removeSelfNestedTemplates } from "../template";
import { renderPageWidgets } from "../../";
import { wrapProps } from "../../../i18n";

const { NO_SPECIAL } = AVAILABILITY_CONSTANTS;

interface DynamicSearchButtonBaseProps extends PageWidgetBaseProps<WidgetOptions> {
    context: CMSProvidedProperties;
    className: string;
    url: string;
    accommodationType?: AccommodationType;
    target: string;
}

interface DynamicSearchButtonState {
    resultCount: number | undefined;
    urlParams: string;
    template?: (Template & WithId) | null;
    templateChildren?: JSX.Element[];
    showTemplate: boolean;
    content?: Content | Content[];
}

export interface DynamicSearchButtonProps extends DynamicSearchButtonBaseProps, DynamicSearchButtonStateProps {}

interface DynamicSearchButtonStateProps {
    dynamicFilter: DynamicFilter;
    availabilityState: AvailabilityState;
    myEnvState: MyEnvState;
}

class DynamicSearchButtonBase extends React.Component<DynamicSearchButtonProps, DynamicSearchButtonState> {
    constructor(props: DynamicSearchButtonProps) {
        super(props);
        const { dynamicFilter, accommodationType, options, url, myEnvState } = this.props;
        this.state = {
            resultCount: 0,
            urlParams: this.getFriendlyUrlForKrim(url + dynamicFilterToUrlQueryParams(dynamicFilter, accommodationType, myEnvState, options)),
            showTemplate: false,
        };
    }

    public componentDidMount() {
        const { dynamicFilter, availabilityState, options, accommodationType, myEnvState } = this.props;
        if (availabilityState.availabilityResult) {
            const resultCount: number | undefined = availabilityState.availabilityResult?.response.resources?.length ?? availabilityState.availabilityResult?.response.units?.length;
            this.setState({ resultCount });
        }
        if (options.conditionalTemplateId) {
            this.setTemplate();
        }
        this.setState({
            urlParams: this.getFriendlyUrlForKrim(this.props.url + dynamicFilterToUrlQueryParams(dynamicFilter, accommodationType, myEnvState, options)),
        });
        if (options?.useDynamicFieldAsLink) {
            loadContent({ props: this.props, widgetOptionsId: options._id }).then((content) => this.setState({ content }));
        }
    }

    public componentDidUpdate(prevProps: any, prevState: Readonly<DynamicSearchButtonState>) {
        const { availabilityState, dynamicFilter, options, accommodationType, myEnvState } = this.props;
        if (availabilityState !== prevProps.availabilityState) {
            if (availabilityState.availabilityResult) {
                const resultCount: number | undefined = availabilityState.availabilityResult?.response.resources?.length ?? availabilityState.availabilityResult?.response.units?.length;
                this.setState({ resultCount });
            }
        }
        if ((dynamicFilter !== prevProps.dynamicFilter || (myEnvState !== prevProps.myEnvState && options.useAsDynamicBookUrlLink)) && !options.useDynamicFieldAsLink) {
            this.setState({
                urlParams: this.getFriendlyUrlForKrim(this.props.url + dynamicFilterToUrlQueryParams(dynamicFilter, accommodationType, myEnvState, options)),
            });
        }
        if (options?.useDynamicFieldAsLink && !isEqual(prevState?.content, this.state?.content)) {
            retrieveLinkFromDynamicField<DynamicSearchButtonProps>(this.props, this.state.content).then((response) => this.setState({ urlParams: response?.url }));
        }
    }

    public render(): JSX.Element | null {
        const { className, context, target, options, availabilityState, myEnvState, isMyEnvWidget, url } = this.props;
        const { urlParams, template, templateChildren, showTemplate } = this.state;

        const localeId = context.currentLocale.locale;
        const localContent = (options.localized || []).find((lc) => lc.locale === localeId);
        const isFetching = isClientSide() && availabilityState.fetching;
        const hideWidget = setOpacityOnHide(options);
        const buttonText = options.showResult ? `${localContent?.labelBeforeCount} ${this.state.resultCount} ${localContent?.labelAfterCount}` : localContent?.buttonText;
        const numberOfAccoTypes = myEnvState.selectedReservation?.reservedResources?.length || 1;
        const showTemplateForMultipleAccoTypes = options.showConditionalContent && options.useForMultipleAccoTypes && options.conditionalTemplateId && numberOfAccoTypes > 1;

        if (options?.useDynamicFieldAsLink && !urlRegex.test(urlParams)) {
            return null;
        }

        if (options.useAsCartButton) {
            const dynamicLinkOptions = {
                url,
                target,
                buttonText: localContent?.buttonText,
            };
            return (
                <div className={`dynamic-search-button ${hideWidget}`}>
                    <ConfirmAddOnsComponent isMyEnvWidget={isMyEnvWidget} context={context} dynamicButtonLinkingOptions={dynamicLinkOptions} useDynamicSearchButton={!!options.useAsCartButton} />
                </div>
            );
        }
        if (showTemplateForMultipleAccoTypes && template && templateChildren) {
            return (
                <div className={`dynamic-search-button ${hideWidget}`}>
                    {
                        <React.Fragment>
                            <NavLink
                                tag={SmartLink}
                                onClick={this.handleTemplateButtonClick.bind(this)}
                                className={classNames(
                                    "button",
                                    className,
                                    { "show-link": options.buttonConfiguration.displayLink },
                                    getPreconfiguredResultButtonClassNames(options.buttonConfiguration)
                                )}
                                style={getInlineStyle(options.buttonConfiguration)}
                                disabled={isFetching}
                                target={target}
                            >
                                <ButtonIcon buttonConfiguration={options.buttonConfiguration} />
                                {isFetching ? <FontAwesome name="spinner" className={classNames("searchfacet-progress", isFetching ? "in-progress" : "no-progress")} /> : null} {buttonText}
                            </NavLink>
                            {showTemplate && (
                                <TemplateWidget
                                    classNames={className || ""}
                                    hideWidget={hideWidget}
                                    template={template}
                                    children={templateChildren}
                                    options={{ ...options, templateId: template._id, layHierarchy: false }}
                                />
                            )}
                        </React.Fragment>
                    }
                </div>
            );
        }
        return !options.useAsCloseButton ? (
            <div className={`dynamic-search-button ${hideWidget}`}>
                {
                    <NavLink
                        tag={SmartLink}
                        href={urlParams}
                        className={classNames("button", className, { "show-link": options.buttonConfiguration.displayLink }, getPreconfiguredResultButtonClassNames(options.buttonConfiguration))}
                        style={getInlineStyle(options.buttonConfiguration)}
                        disabled={isFetching}
                        target={target}
                    >
                        <ButtonIcon buttonConfiguration={options.buttonConfiguration} />
                        {isFetching ? <FontAwesome name="spinner" className={classNames("searchfacet-progress", isFetching ? "in-progress" : "no-progress")} /> : null} {buttonText}
                    </NavLink>
                }
            </div>
        ) : (
            <RevealerContext.Consumer>
                {({ revealerToggle }) => (
                    <div className={`${hideWidget}`}>
                        <NavLink
                            className={classNames("button dynamic-search-button", className, getPreconfiguredResultButtonClassNames(options.buttonConfiguration))}
                            style={getInlineStyle(options.buttonConfiguration)}
                            onClick={() => revealerToggle()}
                        >
                            <ButtonIcon buttonConfiguration={options.buttonConfiguration} />
                            {isFetching ? <FontAwesome name="spinner" className={classNames("searchfacet-progress", isFetching ? "in-progress" : "no-progress")} /> : null} {buttonText}
                        </NavLink>
                    </div>
                )}
            </RevealerContext.Consumer>
        );
    }

    // eslint-disable-next-line max-lines-per-function
    private getFriendlyUrlForKrim(defaultURL: string): string {
        const { context, dynamicFilter, accommodationType, options, myEnvState } = this.props;
        const accoKindIds: number[] | undefined = dynamicFilter.accokindids;
        const resortIds: number[] | undefined = dynamicFilter.resortids;
        const { enableDefaultURL } = options;
        if (
            dynamicFilter.startdate ||
            dynamicFilter.enddate ||
            (enableDefaultURL && resortIds?.length !== 1 && accoKindIds?.length !== 1) ||
            (!context.site.host.includes("krim") && !context.site.host.includes("krm") && !context.site.host.includes("texelcampings"))
        ) {
            return defaultURL;
        }
        if (enableDefaultURL && resortIds && resortIds.length > 1 && accoKindIds?.length === 1) {
            return defaultURL;
        }
        const resortCode: number | undefined = resortIds?.[0];
        const accoKindId: number | undefined = accoKindIds?.[0];
        let modifiedUrl = "";
        if (context.site.host.includes("texelcampings")) {
            if (resortCode) {
                switch (context.currentLocale.code) {
                    case "nl":
                        switch (resortCode) {
                            case 57522:
                                modifiedUrl = "/loodsmansduin/prijzen-en-beschikbaarheid";
                                break;
                            case 57521:
                                modifiedUrl = "/kogerstrand/prijzen-en-beschikbaarheid";
                                break;
                            case 22:
                                modifiedUrl = "/camping-de-krim/prijzen-en-beschikbaarheid";
                                break;
                            case 57523:
                                modifiedUrl = "/camping-de-shelter/prijzen-en-beschikbaarheid";
                                break;
                            default:
                                break;
                        }
                        break;
                    case "de":
                        switch (resortCode) {
                            case 57522:
                                modifiedUrl = "/loodsmansduin/preise-und-verfuegbarkeit";
                                break;
                            case 57521:
                                modifiedUrl = "/kogerstrand/preise-und-verfuegbarkeit";
                                break;
                            case 22:
                                modifiedUrl = "/camping-de-krim/preise-und-verfuegbarkeit";
                                break;
                            case 57523:
                                modifiedUrl = "/camping-de-shelter/preise-und-verfuegbarkeit";
                                break;
                            default:
                                break;
                        }
                        break;
                    default:
                        break;
                }
                if (modifiedUrl) {
                    const { amenities, ...params } = parse(dynamicFilterToUrlQueryParams(dynamicFilter, accommodationType, myEnvState, options));
                    return `${modifiedUrl}?${stringify(params, { encode: false, strict: false })}`;
                }
                return defaultURL;
            } else if (accoKindId) {
                switch (context.currentLocale.code) {
                    case "nl":
                        switch (accoKindId) {
                            case 52:
                                modifiedUrl = "/kamperen/chalet";
                                break;
                            case 53:
                                modifiedUrl = "/kamperen/kampeerplaats";
                                break;
                            case 16545:
                                modifiedUrl = "/kamperen/tent-huren";
                                break;
                            case 49:
                                modifiedUrl = "/kamperen/trekkershut";
                                break;
                            case 20545:
                                modifiedUrl = "/kamperen/camperplaats";
                                break;
                            default:
                                break;
                        }
                        break;
                    case "de":
                        switch (accoKindId) {
                            case 52:
                                modifiedUrl = "/unterkunfte/mobilheime";
                                break;
                            case 53:
                                modifiedUrl = "/unterkunfte/campingplatz";
                                break;
                            case 16545:
                                modifiedUrl = "/unterkunfte/eingerichtete-zelte";
                                break;
                            case 49:
                                modifiedUrl = "/unterkunfte/wanderhutten";
                                break;
                            case 20545:
                                modifiedUrl = "/unterkunfte/wohnmobilplatz";
                                break;
                            default:
                                break;
                        }
                        break;
                    default:
                        break;
                }
                if (modifiedUrl) {
                    const { accokindids, ...params } = parse(dynamicFilterToUrlQueryParams(dynamicFilter, accommodationType, myEnvState, options));
                    return `${modifiedUrl}?${stringify(params, { encode: false, strict: false })}`;
                }
                return defaultURL;
            }
        } else {
            if (resortCode) {
                switch (context.currentLocale.code) {
                    case "en":
                        switch (resortCode) {
                            case 22:
                                modifiedUrl = "/holiday-park-de-krim/prices-and-availability";
                                break;
                            case 23:
                                modifiedUrl = "/residentie-californie/prices-and-availability";
                                break;
                            case 65521:
                                modifiedUrl = "/bungalow-park-hoogelandt/prices-and-availability";
                                break;
                            case 54521:
                                modifiedUrl = "/hotel-molenbos/prices-and-availability";
                                break;
                            case 57521:
                                modifiedUrl = "/campsite-kogerstrand/prices-and-availability";
                                break;
                            case 57522:
                                modifiedUrl = "/campsite-loodsmansduin/prices-and-availability";
                                break;
                            case 57523:
                                modifiedUrl = "/campsite-de-shelter/prices-and-availability";
                                break;
                            case 63521:
                                modifiedUrl = "/villa-park-de-koog/prices-and-availability";
                                break;
                            default:
                                break;
                        }
                        break;
                    case "nl":
                        switch (resortCode) {
                            case 22:
                                modifiedUrl = "/vakantiepark-de-krim/prijzen-en-beschikbaarheid";
                                break;
                            case 23:
                                modifiedUrl = "/residentie-californie/prijzen-en-beschikbaarheid";
                                break;
                            case 65521:
                                modifiedUrl = "/bungalowpark-hoogelandt/prijzen-en-beschikbaarheid";
                                break;
                            case 54521:
                                modifiedUrl = "/hotel-molenbos/prijzen-en-beschikbaarheid";
                                break;
                            case 57521:
                                modifiedUrl = "/camping-kogerstrand/prijzen-en-beschikbaarheid";
                                break;
                            case 57522:
                                modifiedUrl = "/camping-loodsmansduin/prijzen-en-beschikbaarheid";
                                break;
                            case 57523:
                                modifiedUrl = "/camping-de-shelter/prijzen-en-beschikbaarheid";
                                break;
                            case 63521:
                                modifiedUrl = "/villapark-de-koog/prijzen-en-beschikbaarheid";
                                break;
                            default:
                                break;
                        }
                        break;
                    case "de":
                        switch (resortCode) {
                            case 22:
                                modifiedUrl = "/ferienpark-de-krim/preise-und-verfuegbarkeit";
                                break;
                            case 23:
                                modifiedUrl = "/residenz-californie/preise-und-verfuegbarkeit";
                                break;
                            case 65521:
                                modifiedUrl = "/bungalowpark-hoogelandt/preise-und-verfuegbarkeit";
                                break;
                            case 54521:
                                modifiedUrl = "/hotel-molenbos/preise-und-verfuegbarkeit";
                                break;
                            case 57521:
                                modifiedUrl = "/camping-kogerstrand/preise-und-verfuegbarkeit";
                                break;
                            case 57522:
                                modifiedUrl = "/camping-loodsmansduin/preise-und-verfuegbarkeit";
                                break;
                            case 57523:
                                modifiedUrl = "/camping-de-shelter/preise-und-verfuegbarkeit";
                                break;
                            case 63521:
                                modifiedUrl = "/villenpark-de-koog/preise-und-verfuegbarkeit";
                                break;
                            default:
                                break;
                        }
                        break;
                    default:
                        break;
                }
                if (modifiedUrl) {
                    const { amenities, ...params } = parse(dynamicFilterToUrlQueryParams(dynamicFilter, accommodationType, myEnvState, options));
                    return `${modifiedUrl}?${stringify(params, { encode: false, strict: false })}`;
                }
                return defaultURL;
            } else if (accoKindId) {
                switch (context.currentLocale.code) {
                    case "en":
                        switch (accoKindId) {
                            case 48:
                                modifiedUrl = "/holiday-home-texel/bungalow";
                                break;
                            case 24545:
                                modifiedUrl = "/campings-texel/youth-campsite";
                                break;
                            case 6545:
                                modifiedUrl = "/hotel-molenbos/prices-and-availability";
                                break;
                            case 51:
                                modifiedUrl = "/residentie-californie/prices-and-availability";
                                break;
                            case 49:
                                modifiedUrl = "/campings-texel/hikers-cabin";
                                break;
                            case 53:
                                modifiedUrl = "/campings-texel/campsite";
                                break;
                            case 16545:
                                modifiedUrl = "/campings-texel/campsite";
                                break;
                            case 20545:
                                modifiedUrl = "/campings-texel/camper-pitch";
                                break;
                            case 52:
                                modifiedUrl = "/holiday-home-texel/chalet";
                                break;
                            case 4545:
                                modifiedUrl = "/hotels-texel/hotel-chalets";
                                break;
                            case 38545:
                                modifiedUrl = "/holiday-home-texel/luxury-villa";
                                break;
                            default:
                                break;
                        }
                        break;
                    case "nl":
                        switch (accoKindId) {
                            case 48:
                                modifiedUrl = "/vakantiehuis-texel/bungalow-huren";
                                break;
                            case 24545:
                                modifiedUrl = "/campings-texel/jongerencamping";
                                break;
                            case 6545:
                                modifiedUrl = "/hotel-molenbos/prijzen-en-beschikbaarheid";
                                break;
                            case 51:
                                modifiedUrl = "/residentie-californie/prijzen-en-beschikbaarheid";
                                break;
                            case 49:
                                modifiedUrl = "/campings-texel/trekkershutten";
                                break;
                            case 53:
                                modifiedUrl = "/campings-texel/kampeerplaatsen";
                                break;
                            case 16545:
                                modifiedUrl = "/campings-texel/ingerichte-tent-huren";
                                break;
                            case 20545:
                                modifiedUrl = "/campings-texel/camperplaatsen";
                                break;
                            case 52:
                                modifiedUrl = "/vakantiehuis-texel/chalet";
                                break;
                            case 4545:
                                modifiedUrl = "/hotels-texel/hotelchalets";
                                break;
                            case 38545:
                                modifiedUrl = "/vakantiehuis-texel/luxe-villa-huren";
                                break;
                            default:
                                break;
                        }
                        break;
                    case "de":
                        switch (accoKindId) {
                            case 48:
                                modifiedUrl = "/ferienhaus-texel/bungalow";
                                break;
                            case 24545:
                                modifiedUrl = "/campings-texel/jugendzeltplatz";
                                break;
                            case 6545:
                                modifiedUrl = "/hotel-molenbos/preise-und-verfuegbarkeit";
                                break;
                            case 51:
                                modifiedUrl = "/residenz-californie/preise-und-verfuegbarkeit";
                                break;
                            case 49:
                                modifiedUrl = "/campings-texel/wanderhuette";
                                break;
                            case 53:
                                modifiedUrl = "/campings-texel/stellplaetze";
                                break;
                            case 16545:
                                modifiedUrl = "/campings-texel/eingerichtetes-zelt";
                                break;
                            case 20545:
                                modifiedUrl = "/campings-texel/wohnmobilstellplatz";
                                break;
                            case 52:
                                modifiedUrl = "/ferienhaus-texel/chalet";
                                break;
                            case 4545:
                                modifiedUrl = "/hotels-texel/hotelchalets";
                                break;
                            case 38545:
                                modifiedUrl = "/ferienhaus-texel/luxus-villa-mieten";
                                break;
                            default:
                                break;
                        }
                        break;
                    default:
                        break;
                }
                if (modifiedUrl) {
                    const { accokindids, ...params } = parse(dynamicFilterToUrlQueryParams(dynamicFilter, accommodationType, myEnvState, options));
                    return `${modifiedUrl}?${stringify(params, { encode: false, strict: false })}`;
                }
                return defaultURL;
            }
        }
        return defaultURL;
    }

    private setTemplate = async () => {
        const { options } = this.props;
        const template = await TemplateApi.findById({ id: options.conditionalTemplateId || "" });
        let templateChildren;
        if (template) {
            const filteredRootTemplates = removeSelfNestedTemplates(template._id, template.root);
            templateChildren = await renderPageWidgets(filteredRootTemplates, this.props.context);
        }
        this.setState({ template, templateChildren });
    };

    private handleTemplateButtonClick() {
        this.setState((prevState) => ({
            showTemplate: !prevState.showTemplate,
        }));
    }
}

export function dynamicFilterToUrlQueryParams(dynamicFilter: DynamicFilter, accommodationType: any, myEnvState?: MyEnvState, widgetOptions?: WidgetOptions): string {
    const params: UrlLinkParams = UrlParamsUtil.getUrlParamsFromFilter(dynamicFilter, { enableStateUuid: !widgetOptions?.disableStateUuid });
    // same special codes will be present in both dynamic filter and accommodation type
    if (accommodationType?.specialCode?.length) {
        const index = accommodationType.specialCode.indexOf(NO_SPECIAL);
        if (index > -1) {
            accommodationType.specialCode.splice(index, 1);
        }
        params.specialcode = accommodationType && accommodationType.specialCode;
    } else {
        params.specialcode = dynamicFilter.specialcode;
    }
    if (dynamicFilter.startdate && dynamicFilter.enddate && !dynamicFilter.duration && !dynamicFilter.stayperioddefid) {
        params.enddate = dynamicFilter.enddate;
    }
    if (dynamicFilter.resourceid || (accommodationType && accommodationType.resourceId)) {
        params.resourceid = (accommodationType && accommodationType.resourceId) || dynamicFilter.resourceid;
    }
    if (widgetOptions?.useAsDynamicBookUrlLink && myEnvState) {
        const selectedResource = myEnvState.selectedReservation?.reservedResources?.[0];
        if (selectedResource) {
            params.resourceid = selectedResource.resourceId;
            params.unitid = selectedResource.unitId;
            params.selectedreservationid = myEnvState.selectedReservationId?.toString();
        }
    }
    return `?${stringify(params, { encode: false, strict: false })}`;
}

function mapStateToProps(state: State): DynamicSearchButtonStateProps {
    return {
        dynamicFilter: state.dynamicFilter,
        availabilityState: state.availabilityState,
        myEnvState: state.myEnvState,
    };
}

const DynamicSearchButton = connect<DynamicSearchButtonStateProps>(mapStateToProps)(DynamicSearchButtonBase);

export const DynamicSearchButtonWidget = wrapProps<DynamicSearchButtonBaseProps>(DynamicSearchButton);

export async function warmpupCache(props: DynamicSearchButtonProps): Promise<void> {
    await AvailabilityUtil.getAvailabilityByDynamicFilter(props.dynamicFilter, undefined, props.context);
}
